<template>
  <div>
      <component :is="rightComponent"></component>
  </div>
</template>

<script>
export default {
  components: {
    transactionStatistics: () => import('../common/transactionStatistics'),
    maintenanceStatistics: () => import('../common/maintenanceStatistics')
  },
  data () {
    return {
      rightComponent: 'transactionStatistics'
    }
  },
  created () {
    this.rightComponent = this.moduleType + 'Statistics'
  },
  methods: {

  },
  computed: {
    moduleType () {
      return this.$store.state.reconciliationDemo.moduleType
    }
  },
  watch: {
    moduleType (val) {
      this.rightComponent = val + 'Statistics'
    }

  }
}
</script>
